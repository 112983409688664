<template>
  <div id="ib_accounts">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="content_title">
          <h2>{{ $t('menu.ibAccs') }}</h2>

          <p></p>
        </div>

        <div class="ib_accounts_content">
          <div class="ib_accounts_select">
            <div class="select">
              <el-form>
                <AccountNumber :label="$t('ibAccounts.ibAccount')"></AccountNumber>
              </el-form>
            </div>

            <div class="select">
              <div class="labelTitle">
                <span class="required_icon">*</span>

                <label for="">{{ $t('ibAccounts.subIbs') }}</label>
              </div>

              <el-cascader
                expand-trigger="hover"
                :options="subIbAccountList"
                v-model="subIbAccount"
                change-on-select
                @change="handleChange"
                v-if="subIbAccountList != undefined && subIbAccountList.length > 0"
                filterable
                :show-all-levels="false"
                @visible-change="handleItemChange"
              ></el-cascader>

              <p v-else>&nbsp;{{ $t('common.keys.NONE') }}</p>
            </div>

            <el-button class="purple_button" :callback="download" :disabled="!hasDownloadPermission">{{
              $t('common.button.download')
            }}</el-button>
          </div>

          <div class="table_box">
            <el-table
              ref="traderTable"
              :data="traderTableData | columnFilter(filters, setFilteredTotal) | tableDataPageFilter(pageNo, size)"
              @sort-change="sortChange"
              @filter-change="handleFilterChange"
              :row-key="traderTableData != undefined ? traderTableData.accountNmber : ' '"
            >
              <el-table-column prop="date" :label="$t('common.keys.DATE')" sortable="custom" min-width="120">
                <template slot-scope="traderTableScope">{{ traderTableScope.row.date | date('DD/MM/YYYY') }}</template>
              </el-table-column>

              <el-table-column prop="accountNmber" :label="$t('common.keys.ACCNUM')" min-width="120"></el-table-column>

              <el-table-column prop="name" :label="$t('common.keys.NAME')" min-width="100">
                <template slot-scope="traderTableScope">
                  <span>{{ traderTableScope.row.name }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="150"></el-table-column>

              <el-table-column
                prop="accountType"
                :label="$t('common.keys.ACCTYPE')"
                :filters="accountTypeOptions"
                column-key="accountType"
                min-width="150"
              >
                <template slot-scope="traderTableScope">
                  {{ $config.accountTypeMaps[traderTableScope.row.accountType] }}
                </template>
              </el-table-column>

              <el-table-column
                prop="platform"
                :label="$t('common.keys.PLATFORM')"
                :filters="platformOptions"
                column-key="platform"
                min-width="120"
              >
                <template slot-scope="traderTableScope">{{ 'MT' + traderTableScope.row.platform }}</template>
              </el-table-column>

              <el-table-column
                prop="baseCurrency"
                :label="$t('common.keys.BASECURRENCY')"
                :filters="currencyOptions"
                column-key="baseCurrency"
                min-width="130"
              ></el-table-column>

              <el-table-column
                prop="accountBalance"
                :label="$t('common.keys.BALANCE')"
                sortable="custom"
                :sort-orders="['ascending', 'descending']"
                min-width="120"
              >
                <template slot-scope="traderTableScope">
                  {{ traderTableScope.row.accountBalance | currency('') }}
                </template>
              </el-table-column>
            </el-table>
          </div>

          <!-- 分页 -->

          <div class="page_box clearfix">
            <el-pagination
              :background="true"
              :page-size="size"
              layout="prev, pager, next"
              :total="filteredTotal"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              :current-page.sync="pageNo"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountNumber from '@/components/form/AccountNumber';
import { getSessionStorage, setSessionStorage, removeSessionStorage } from '@/util';
import LoadingButton from '@/components/LoadingButton';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import { apiIbAccounts } from '@/resource';
import { defaultVariableMapping } from '@/components/profile/SubProfile.js';

export default {
  name: 'IbAccount',
  components: { AccountNumber, LoadingButton },
  data() {
    return {
      none: this.$t('common.keys.NONE'),
      all: this.$t('common.keys.ALL'),
      userId: this.$store.state.common.CUID,
      account: '',
      subIbAccountList: [],
      subIbAccount: [],
      selectedIbAccount: '',
      traderUrl: 'retail_clients',
      subIbUrl: 'sub_ibs/customer',
      filteredTotal: 0,
      traderTableData: [],
      platformOptions: [],
      currencyOptions: [],
      pageNo: 1,
      size: 25,
      filters: {},
      accountTypeOptions: []
    };
  },
  watch: {
    ibAccount: {
      handler(newValue) {
        this.$nextTick(() => {
          if (newValue) this.ibAccOnChange(newValue);
        });
      },
      immediate: true
    },
    traderTableData(newData) {
      this.buildFilterOptions();
    },
    selectedIbAccount(newAccount) {
      this.removeSortLabels();
      //clear table filters
      this.filters = {};
      this.$refs.traderTable.clearFilter();
      if (newAccount === this.all) {
        this.traderTableData = getSessionStorage('TTD' + newAccount + this.ibAccount);
        if (this.traderTableData) {
          return;
        }
        this.getIbAccounts(this.userId, this.ibAccount, 'all_clients').then(resp => {
          if (resp.data.code === 0) {
            this.traderTableData = resp.data.data;
            setSessionStorage('TTD' + newAccount + this.ibAccount, this.traderTableData);
          }
        });
        return;
      }
      if (newAccount === this.none) {
        this.traderTableData = getSessionStorage('TTD' + this.ibAccount);
        return;
      }
      this.traderTableData = getSessionStorage('TTD' + newAccount);
      if (this.traderTableData) {
        return;
      }
      this.getIbAccounts(this.userId, newAccount, this.traderUrl).then(resp => {
        if (resp.data.code === 0) {
          this.traderTableData = resp.data.data;
          setSessionStorage('TTD' + newAccount, this.traderTableData);
        }
      });
    }
  },
  methods: {
    download() {
      if (!this.traderTableData || !this.traderTableData.length) {
        this.$message({
          message: this.$t('report.nodataMessage'),
          type: 'warning'
        });
        return Promise.resolve();
      }
      const fileName = `ib_accounts_${moment(this.startDate).format('YYYY-MM-DD')}_${moment(this.endDate).format(
        'YYYY-MM-DD'
      )}_${moment().format('YYYY-MM-DD HH:mm:ss A')}`;
      const ibaccountHeader = [
        'common.keys.DATE',
        'home.Account',
        'home.Name',
        'common.keys.EMAIL',
        'common.keys.ACCTYPE',
        'common.keys.PLATFORM',
        'common.keys.BASECURRENCY',
        'common.keys.BALANCE',
        'common.keys.PROFIT',
        `${this.$t('common.keys.MARGINLEVEL')}(%)`,
        'common.keys.ACCOUNTEQUITY',
        'common.keys.CREDIT'
      ].map(key => this.$t(key));

      let option = {
        fileName,
        datas: [
          {
            sheetData: this.parseExcelData(this.traderTableData),
            sheetName: 'Summary Details',
            sheetHeader: ibaccountHeader,
            columnWidths: [5, 7, 6, 8, 7, 8, 8, 7, 7, 7, 6, 8]
          }
        ]
      };
      const toExcel = new ExportJsonExcel(option);
      toExcel.saveExcel();
      return Promise.resolve();
    },
    parseExcelData(traderTableData) {
      return traderTableData.map(item => {
        const {
          accountBalance,
          accountNmber,
          accountType,
          bPayNumber,
          baseCurrency,
          credit,
          date,
          email,
          equity,
          marginLevel,
          name,
          phone,
          platform,
          profit,
          userId
        } = item;
        return {
          date: moment(date).format('YYYY-MM-DD'),
          accountNmber,
          name,
          email,
          accountType: this.$config.accountTypeMaps[accountType],
          platform: 'MT' + platform,
          baseCurrency,
          balance: accountBalance,
          profit,
          marginLevel,
          equity,
          credit
        };
      });
    },
    ibAccOnChange(newValue) {
      //clear table filters
      this.filters = {};
      this.$refs.traderTable.clearFilter();

      this.selectedIbAccount = newValue;
      this.subIbAccount = ['none'];
      this.subIbAccountList = getSessionStorage('SUBIB' + newValue);
      //return if cached result is found and the result has child menu
      if (this.subIbAccountList && this.subIbAccountList.some(e => e.hasOwnProperty('children'))) {
        return;
      }
      //retrieve sub ib accounts under the current ib account
      this.getIbAccounts(this.userId, newValue, this.subIbUrl).then(resp => {
        if (resp.data.code === 0 && resp.data.data.length > 0) {
          this.subIbAccountList = this.buildCascaderOption(resp.data.data);
          this.subIbAccountList.forEach(e => {
            //retrieve the second level of sub ib accounts
            this.getIbAccounts(this.userId, this.getAccountFromLabel(e.label), this.subIbUrl).then(resp => {
              if (resp.data.code === 0 && resp.data.data.length > 0) {
                this.$set(e, 'children', this.buildCascaderOption(resp.data.data));
              }
            });
          });
          this.subIbAccountList.push({ value: 'all', label: this.all });
          this.subIbAccountList.unshift({ value: 'none', label: this.none });
          this.$nextTick(() => setSessionStorage('SUBIB' + newValue, this.subIbAccountList));
        }
      });
    },
    handleFilterChange(filters) {
      let [key] = Object.keys(filters);
      let flag = true; //check if it is a same filter. no update for same filters
      if (this.filters[key]) {
        if (filters[key].length === this.filters[key].length) {
          for (let i = 0, end = filters[key].length; i < end; i++) {
            if (filters[key][i] != this.filters[key][i]) {
              flag = false;
              break;
            }
          }
          if (flag) {
            return;
          }
        }
      }
      this.$set(this.filters, key, filters[key]);
    },
    // 排序
    sortChange(column) {
      if (column.column == null) {
        let key = 'TTD' + this.selectedIbAccount;
        if (this.selectedIbAccount === this.all) {
          key += this.ibAccount;
        }
        this.traderTableData = getSessionStorage(key);
        return;
      }
      this.traderTableData = getSessionStorage(
        'TTD' + this.ibAccount + this.selectedIbAccount + column.prop + column.order
      );
      if (this.traderTableData) {
        return;
      }
      let sortUrl = 'sort_clients';
      let account = this.selectedIbAccount;
      if (this.selectedIbAccount === this.all) {
        sortUrl = 'sort_all_clients';
        account = this.ibAccount;
      }
      this.getSortedTable(this.userId, account, column.prop, column.order === 'ascending' ? 1 : 0, sortUrl).then(
        resp => {
          if (resp.data.code === 0) {
            this.traderTableData = resp.data.data;
            setSessionStorage(
              'TTD' + this.ibAccount + this.selectedIbAccount + column.prop + column.order,
              this.traderTableData
            );
          } else {
            this.traderTableData = [];
          }
        }
      );
    },
    removeSortLabels() {
      const sortElements = $('.el-table__header .is-leaf.is-sortable');
      for (let i = 0, end = sortElements.length; i < end; i++) {
        if (sortElements[i].classList.contains('descending')) {
          sortElements[i].click();
        } else if (sortElements[i].classList.contains('ascending')) {
          sortElements[i].click();
          sortElements[i].click();
        }
      }
    },
    handleCurrentChange(val) {
      this.pageNo = val;
    },
    handleSizeChange(size) {
      this.size = size;
    },
    handleItemChange(flag) {
      if (!flag) {
        let subIbAccount = this.getSelectedSubIb(this.subIbAccount);
        if (subIbAccount == undefined) {
          return;
        }
        switch (subIbAccount.label) {
          case this.all:
            this.selectedIbAccount = subIbAccount.label;
            break;
          case this.none:
            this.selectedIbAccount = this.ibAccount;
            break;
          default:
            this.selectedIbAccount = this.getAccountFromLabel(subIbAccount.label);
            break;
        }
      }
    },
    setFilteredTotal(total) {
      this.filteredTotal = total;
    },
    getAccountFromLabel(label) {
      let nameAndAccount = label.split(' ');
      return nameAndAccount[nameAndAccount.length - 1];
    },
    buildCascaderOption(accountList) {
      let resultOption = [];
      accountList.forEach((e, i) => {
        resultOption.push({
          value: i,
          label: e.name + ' ' + e.account
        });
      });
      return resultOption;
    },
    getIbAccounts(userId, account, url) {
      return apiIbAccounts(url, {
        userId: userId,
        account: account
      }).finally(resp => {
        return resp;
      });
    },
    getSortedTable(userId, account, sortingField, sortBy, url) {
      return apiIbAccounts(url, {
        userId: userId,
        account: account,
        sortingField: sortingField,
        sortBy: sortBy
      }).finally(resp => {
        return resp;
      });
    },
    getSelectedSubIb(value) {
      //index 0 is "none"
      if (value.indexOf('none') > -1) {
        return this.subIbAccountList[0];
      }
      if (value.indexOf('all') > -1) {
        return this.subIbAccountList[this.subIbAccountList.length - 1];
      }
      let topLevelIndex = value[0] + 1;
      let selectedSubIb = this.subIbAccountList[topLevelIndex];
      // trace down to the last parent ib account according to passed-in value
      for (let i = 1, end = value.length; i < end; i++) {
        if (selectedSubIb.children) {
          selectedSubIb = selectedSubIb.children[value[i]];
        }
      }
      return selectedSubIb;
    },
    // 下拉菜单几级联动
    handleChange(value) {
      if (value.indexOf('none') > -1) {
        this.selectedIbAccount = this.ibAccount;
        return;
      }
      if (value.indexOf('all') > -1) {
        this.selectedIbAccount = this.all;
        return;
      }
      let curChild = this.getSelectedSubIb(value).children;
      if (curChild != undefined && !curChild.loaded) {
        curChild.forEach(e => {
          if (e.children == undefined) {
            //retrieve the second level of sub ib accounts
            this.getIbAccounts(this.userId, this.getAccountFromLabel(e.label), this.subIbUrl).then(resp => {
              if (resp.data.code === 0 && resp.data.data.length > 0) {
                this.$set(e, 'children', this.buildCascaderOption(resp.data.data));
              } else if (resp.data.code === 305) {
                this.$set(e, 'children', []);
              }
            });
          }
        });
        curChild.loaded = true;
        setSessionStorage('SUBIB' + this.ibAccount, this.subIbAccountList);
      }
    },
    buildFilterOptions() {
      this.accountTypeOptions = [];
      this.platformOptions = [];
      this.currencyOptions = [];
      let accountTypeSet = new Set();
      let platformSet = new Set();
      let currencySet = new Set();
      if (this.traderTableData == undefined) {
        return;
      }
      this.traderTableData.forEach(e => {
        accountTypeSet.add(e.accountType);
        platformSet.add(e.platform);
        currencySet.add(e.baseCurrency);
      });
      accountTypeSet.forEach(e => {
        this.accountTypeOptions.push({
          text: this.$config.accountTypeMaps[e],
          value: e
        });
      });
      platformSet.forEach(e => {
        this.platformOptions.push({
          text: 'MT' + e,
          value: e
        });
      });
      currencySet.forEach(e => {
        this.currencyOptions.push({
          text: e,
          value: e
        });
      });
    },
    clearSubibCache() {
      Object.keys(sessionStorage).forEach(e => {
        if (/^(SUBIB|TTD){1}[\s\S]*$/.test(e)) {
          removeSessionStorage(e);
        }
      });
    }
  },
  created() {
    this.clearSubibCache();
  },
  filters: {
    columnFilter(tableData, filters, setFilteredTotal) {
      if (tableData == undefined) {
        return;
      }
      let keys = Object.keys(filters);
      if (keys.length > 0) {
        tableData = tableData.filter(element => {
          let flag = true;
          for (let i = 0, end = keys.length; i < end; i++) {
            if (filters[keys[i]] != undefined && filters[keys[i]].length > 0) {
              if (filters[keys[i]].indexOf(element[keys[i]]) < 0) {
                flag = false;
                break;
              }
            }
          }
          return flag;
        });
      }
      if (tableData) {
        setFilteredTotal(tableData.length);
      }
      return tableData;
    },
    tableDataPageFilter(tableData, pageNo, size) {
      if (tableData == undefined) {
        return;
      }
      let start = (pageNo - 1) * size;
      let end = pageNo * size;
      return tableData.slice(start, end);
    }
  },
  computed: {
    ibAccount() {
      return this.$store.state.behavior.AID;
    },
    hasDownloadPermission() {
      return this.$store.state.common.ibDataPermission;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/ibAccounts.scss';
</style>
